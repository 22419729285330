<template>
  <div class="container">
    <div class="col-lg-4 col-md-6 ml-auto mr-auto">
      <form @submit.prevent="login">
        <card class="card-login card-white" style="margin-top:150px">
          <template slot="header">
            <div id="logo_header">
              <img src="/img/logo.png" alt="" />
            </div>
          </template>

          <div>
            <base-input
              v-validate="'required|email'"
              name="email"
              :error="emailError"
              v-model="model.email"
              placeholder="Digite um email para redefinir a senha"
              addon-left-icon="tim-icons icon-email-85"
            >
            </base-input>
            <!-- <router-link to="/forgot" class="text-right d-block">Esqueci minha senha</router-link> -->
          </div>
          <div slot="footer">
            <base-button
              native-type="submit"
              type="primary"
              :disabled="loading"
              class="mb-3"
              size="lg"
              block
            >
              <i class="fas fa-circle-notch" v-if="loading"></i>
              Enviar email
            </base-button>

            <div class="pull-right">
              <small>
                {{ version }}
              </small>
            </div>
          </div>
        </card>
      </form>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { version } from '../../../package.json'

export default {
  data () {
    return {
      model: {
        email: '',
        password: '',
        submit: false
      },
      loading: false,
      invalidCredentials: false,
      serverError: false,
      invalidAccess: false
    }
  },
  methods: {
    getError (fieldName) {
      return this.errors.first(fieldName)
    },
    async login () {
      this.model.submit = true
      const isValidForm = await this.$validator.validateAll()
      if (isValidForm) {
        this.invalidCredentials = false
        this.invalidAccess = false
        this.serverError = false
        this.loading = true
        const isLoged = await this.$jwt.accessToken(this.model)
        if (isLoged === 200) {
          const { roles } = this.user
          if (!roles.map(role => role.role).includes('admin')) {
            this.$jwt.logout()
            this.invalidAccess = true
            this.form = {}
          } else {
            this.$router.replace('/')
          }

          this.loading = false
        }
        if (isLoged !== 200) this.loading = false
        if (isLoged === 401) {
          this.invalidCredentials = true
          this.serverError = false
        }
        if (isLoged === 500) {
          this.invalidCredentials = false
          this.serverError = true
        }
      }
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    emailError () {
      if (
        // eslint-disable-next-line no-useless-escape
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.model.email)
      ) {
        return
      }
      return this.model.submit ? 'O campo email é obrigatório' : ''
    },
    version () {
      return `V. ${version}`
    }
  }
}
</script>
<style>
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}

.login-page .card-login .card-header {
  padding: 0;
}

.card-login img {
  margin: 1rem 0;
}

.fa-circle-notch {
  animation: loading 1.5s linear infinite;
  margin-right: 1rem;
  font-size: 1.1rem;
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

#logo_header {
  height: 5rem;
  position: relative;
}

#logo_header img {
  max-width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  margin: auto !important;
}
</style>
